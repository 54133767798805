.calnav {
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.calnav button {
    border:none;
    background:transparent;
   
}

.calnav .bachicon {
    width:1.5rem;
    height:1.5rem;    
}

.calendar_month tr:first-child .weekcell {
    background-color: var(--mainmedium);
    color: var(--bgcolor);
}

.table .weekcell {
    padding:0;
    width:50px;
    border:1px solid var(--bglight) !important;
    background:var(--bgcolor);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.monthcell a {
    height:40px;
    line-height:30px;
    vertical-align:middle;
}


td.weekcell.public_holiday a,
td.monthcell.public_holiday a {
    /*box-shadow:0px 0px 0px 1px var(--invalid50p) inset !important;*/
    color:var(--invalid) !important; 
}

td.weekcell.recess a,
td.monthcell.recess a {
    color:var(--invalid) !important; 
}

td.weekcell.sunday a,
td.monthcell.sunday a {
    /*box-shadow:0px 0px 0px 1px var(--invalid) inset !important;*/
    color:var(--invalid) !important; 
}

.calendar_week .weekcell a {
    padding-bottom:1rem;
}

.weekcell a {
    color:var(--text) !important;
    font-weight:400 !important;
    text-decoration: none;
    display:block;
    padding:0.25rem 0;
    position:relative;
   /* transition: background-color 0.5s; */
}
.weekcell a>span.small {
    transition: text-shadow 0.5s;
}
.weekcell a.active {
    background:var(--light) !important;
    transition: background-color 0.5s; 
    color:var(--black) !important;
}

.weekcell a:hover {
    background:var(--light); 
    transition: background-color 0.5s; 
    color:var(--black) !important;
}

@media (hover: none) {
    .weekcell a:hover {
        background: transparent;
    }
}

.weekcell a:hover>span.small, .weekcell a.active>.span.small{
    text-shadow: 
    -0.3px -0.3px 0 black,
    0.3px -0.3px 0 black,
    -0.3px 0.3px 0 black,
    0.3px 0.3px 0 black;
    transition: text-shadow 0.5s;
}

.weekcell a .dots {
    position:absolute;
    left:0;
    right:0;
    bottom:5px; /*-2px;*/
    text-align:center;
    line-height:0;
}

.weekcell a .dots i {
    /*opacity:0;*/
    width:4px;
    height:4px;
    display:inline-block;
    border-radius:50%;
    margin:0 1px;
}

.weekcell a .dots i.exam {
    background:var(--extra);
} 

.weekcell a .dots i.course {
    background:var(--mainmedium);
} 

.weekcell a .dots i.rooms {
    background: var(--internalbooking);
}

.calnav-text{
    min-width: 2rem;
}

.hiddendatefield {
    width:1px;
    position:absolute;
    height:0px;
    border:none;
    opacity:0.000001;
}

.clickabledate {
    cursor:pointer;
    border-bottom:1px dashed var(--lightgrey);
}