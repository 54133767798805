.bd-modal-header {
    border: none;
}

.bd-modal-header>div {
    font-weight: 600;
}

.bd-modal-header>button {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.bd-modal-body {
    border: none;
    color: black;
    background-color: var(--light);
    font-weight: 300;
}

.browser-name {
    text-decoration: underline dotted;
}

.browser:not(:last-child):after {
    content: ',';
}

.browser:last-child:after {
    content: '.';
}

.bd-main-text {
    margin: auto;
}

.bd-icon {
    height: 4rem;
    width: 4rem;
    margin: auto;
}