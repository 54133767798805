.error {
    background-color: var(--invalid);
}

.success {
    background-color: var(--valid);
}

.error,
.success {
    width: auto;
    border-radius: 0;
    color: var(--bgcolor);
    padding-left: 2rem !important;
    position: relative;
}

.error::before,
.success::before {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ffffff'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ffffff' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: left center;
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}