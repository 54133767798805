.notification_box.course {
     background:#2B7A7810; 
}
.notification_box.exam {
    background:#F7973310; 
}
.notification_box.recess {
    background:#dc354510; 
}

.notification_box.course strong {
    color:var(--maindark);
}

.notification_box.exam strong {
    color:var(--extra);
}

.notification_box.recess strong {
    color:var(--invalid);
}

.notification_box em { 
    font-size:0.75em;
    opacity:0.5;
}

.notification_box.read {
    background:transparent !important; 
}

.notification_box .btn.bachicon {
    position:absolute;
    top:0;
    right:0;
}

.notification_text {
    margin-bottom:1rem;
    font-weight:300;    
    overflow:hidden;
    position:relative;
}

.notification_more, .notification_less {
    text-align:right;
    font-weight:300;
    margin-top:-1rem;
    display:none;
}

.notify_closed .notification_text {
    max-height:4.5rem;
}

.notify_closed .notification_more {
    display:block;
}

.notification_less {
    display:block;    
}

.notify_closed .notification_less {
    display: none;
}