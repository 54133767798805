/* theses overview */

/* data table */

.theses-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    padding: 0
}

.theses-table {
    margin: 0;
}

.theses-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable > div {
    padding-left: 0.7rem !important;
    padding-right: 0.2rem !important;
    font-size: 0.95rem;
    font-weight: 500;
    align-self: center;
}

.theses-table .table-container .rdt_TableCell {
    padding-left: 0.7rem !important;
    padding-right: 0.2rem !important;
    font-size: 0.95rem;
    font-weight: 500;
}

.thesis-overview div[data-column-id="titleOrig"] li,
.thesis-overview div[data-column-id="titleEn"] li {
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thesis-overview .tmg-col-admin {
    max-width: 100px;  
}

.thesis-overview .tmg-col-manager,
.thesis-overview .tmg-col-teacher {
    max-width: 130px;  
}

.thesis-overview .tmg-col-student {
    max-width: 160px;  
}

.thesis-overview .theses-table span.badge.rounded-pill {
    font-size: 0.7rem;
    font-weight: 600;
    margin-left: 0.2rem;
    width: 1.1rem;
    height: 1.1rem;
    padding: 0.2rem;
    vertical-align: text-top;
    background-color: var(--badgehighlight) !important;
}

/* filter */

.thesis-overview .card {
    border: none;
}

.thesis-overview .card-body {
    padding:0;
    margin: 0; 
    background-color: var(--bgcolor);
}

.thesis-overview .card-body .col-sm-4{
    max-width: 330px;
}

.thesis-overview .card-body span {
    font-weight: 600;
}

/* role buttons */

.thesis-overview .btn-role{
    width: 200px;
    font-size: 1rem;
    height: 2.5rem;
    min-height: 2.5rem;
    margin-left: 0.5rem;
}

.thesis-overview .btn-role.btn-secondary {
    cursor: not-allowed;  
}

@media screen and (min-width: 768px) {

    /* data table */

    .thesis-overview .tmg-col-admin {
        max-width: 120px;
    }

    .thesis-overview .tmg-col-manager,
    .thesis-overview .tmg-col-teacher {
        max-width: 150px;
    }

    .thesis-overview .tmg-col-student {
        max-width: 180px;
    }

}

@media screen and (min-width: 1200px) {

    /* data table */

    .thesis-overview .tmg-col-admin {
        max-width: 170px;
    }

    .thesis-overview .tmg-col-manager,
    .thesis-overview .tmg-col-teacher {
        max-width: 200px;
    }

    .thesis-overview .tmg-col-student {
        max-width: 230px;
    }

}

@media screen and (min-width: 1400px) {

    /* data table */
    
    .thesis-overview .tmg-col-admin {
        max-width: 220px;
    }

    .thesis-overview .tmg-col-manager {
        max-width: 270px;
    }

    .thesis-overview .tmg-col-teacher,
    .thesis-overview .tmg-col-student {
        max-width: 280px;
    }

}

