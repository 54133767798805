.editImage {
    position:relative;
    display:block;
    cursor:pointer;
    padding-top:1px;
}

.infobox_wrapper {
    min-height:150px;
}

.avatar-wrapper {
    width:110px;
    height:110px;  
    position:absolute;
    /*
    top:0;
    left:0;
    z-index:0;
    */
    background:var(--bgwulogo);   
    border:4px solid var(--white);
    border-radius:50%;
    overflow:hidden;
    box-shadow:0 0 10px rgba(0,0,0,0.25);
 
}

.avatar-wrapper > div {
    width:100%;
    height:100%;
    background-size:cover;
    background-position:center center;
}

.avatar-wrapper + .loader {
    position:absolute;
    top:0;
    left:0;
}

.editImage .icon {
    display:block;
    width:40px;
    height:40px;
    position:absolute;
    top:0px;
    left:0px;
    background-color:var(--mainmedium);
    background-position:center center;
    background-repeat:no-repeat;
    border-radius:50%;
}

 #remove-image {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	top: 0px;
	left: 0px;
	margin-left: 6rem;
	background-color: var(--invalid);
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 50%;
	color: white;
	border: 7px solid var(--invalid);
	cursor: pointer;
}

#avatarUpload {
    display:none;
}

.ul-square {
    list-style: none;
  }

.ul-square li::before {
    content: "•";
    color: var(--mainmedium);
    font-weight: bold;
    display: inline-block;
    width: 15px;
  }

/*
.editImage > div {
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    display:flex;
    align-items: center;
    justify-content: center;
    margin:0 !important;
    padding:0 !important;
}
*/

.loginMappingBox {
    border-left: 3px solid var(--invalid)
}

.loginMappingBox h2 {
    color: var(--invalid)
}

.loginMappingBox .toast {
    margin: 0.5rem 0;
}

.closed-study {
    color: #495057;
    text-decoration: line-through;
    background-color: #f1f3f5;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }