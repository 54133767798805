.featurebox h2 {
    font-weight:800;
    margin:0 auto;
    max-width:75%;
    border-bottom:1px solid var(--text);
    padding:0 0.5rem;
    text-align:center;
}

.featurebuttons ul {
    list-style:none;
    padding-left:0;
    margin-bottom:0;
}

.featurebuttons li {
    position:relative;
}

.infobox .featurebuttons a {
    background-color:var(--bgcolor);
}

.featurebuttons a {
    display:flex;
    border:1px solid var(--borderfeaturebutton);
    background-color:var(--bgfeaturebutton);
    color:var(--mainmedium) !important; 
    text-decoration:none;
    text-align:center;
    font-weight:700;
    min-height:80px;
    flex-direction:column;
    justify-content: center;
    /*height:100%;*/
    line-height:1.2;
    
}

.favorites .featurebuttons a {
    min-height:110px;
}

.featurebuttons a.highlight {
    background-color:var(--extra) ;
    color:var(--white) !important;
    border:none;
}

.featurebuttons a.dark {
    background-color:var(--mainmedium) ;
    color:var(--white) !important;
    border:none; 
}

.featurebuttons a.light {
    background-color:var(--mainlight) ;
    border-color:var(--mainlight);
    border:none;
}

.featurebuttons a.oehwu {
    background-color:var(--oehwu);
    color:var(--white) !important;
    border:none;
}

.featurebuttons a.ghost {
    background-color:var(--bgcolor) ;
    color:var(--text) !important;
    border-color:var(--text20p);
    /*border:none;*/
    opacity:0.35;
}

.featurebuttons a span, .bachicon {
    display:block;
    height:3rem;
    width:3rem;
    background-position:center center;
    background-size:auto 85%;
    background-repeat: no-repeat;
}


.featurebuttons a span {
    margin:0.25rem auto 0;
}

.removeTile, .addTile {
    background:var(--maindark);
    border:none;
    border-radius:50%;
    color:var(--bgcolor);
    font-size:2.5rem;
    width:2.5rem;
    height:2.5rem;
    line-height: 0.5em;
    position:absolute;
    top:-0.75rem;
    left:0.25rem;
    box-sizing:border-box; /* iOS Safari oval problem */
    padding:0; /* iOS Safari oval problem */
}

.addTile {
    left:auto;
    right:0.25rem;
    
}


.editable {
    opacity:0.5;
}

.isactive {
    opacity:1;
    transform:scale(0.9) rotate(5deg)
}

