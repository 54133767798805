.datebox {
    border: 1px solid var(--bglight);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-left-width: 3px;
    position: relative;
    background:var(--bgcolor);
}

.boxseperator {
    border-bottom: 1px solid var(--light);
}

.datebox.exam {
    border-left-color: var(--extra);
}

.datebox.course {
    border-left-color: var(--mainmedium);
    transition:box-shadow 1s ease-out;
}

.datebox.recess,
.datebox.public_holiday {
    border-left-color: var(--invalid);
}

.datebox.internal_booking {
    border-left-color: var(--internalbooking);
    transition:box-shadow 1s ease-out;
}

main a.datebox {
    text-decoration: none;
    color: var(--text);
    font-weight: normal;
    background:var(--bgfeaturebutton);
}

.noborder tr:first-child {
    border-top-width: 0;
}

.noborder tr:last-child {
    border-bottom-width: 0;
}

.noborder td:first-child {
    border-left-width: 0;
}

.noborder td:last-child {
    border-right-width: 0;
}

h2 span.bachicon {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    cursor: pointer;
}
/*
.datebox table {
    line-height:1.2;
}
*/

.datebox .btn-close,
.box-functions {
    position:absolute;
    top:5px;
    right:5px;
   
}

.box-functions { 
    width:auto;
}

.box-functions .btn {
    width:1em;
    height:1em;
    padding:0.25em;
    box-sizing: content-box;
    box-shadow:none !important;
    border:0;
    margin-left:0.5rem;
}