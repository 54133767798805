#wrapper {
    padding:0;
    min-width:320px;
}

#main > * {
    /*min-height:100.1vh;*/ /*only when header&footer fixed*/
    min-height:calc(100.1vh - 118px);
    position:relative;   
}

.mode_ios #main > * {
    min-height:calc(100.1vh - 118px - 1.75rem - 10px);
}

#main > * > :last-child {
    padding-bottom:50px !important;
}


.colormedium {
    color: var(--mainmedium);
}

.code {
    border:1px solid var(--grey);
    font-size:0.8rem;
    background-color:var(--bgcode);
    color:var(--grey);
 }

 dd {
    padding-left:2rem;
    padding-bottom:0.5rem;
 }

 /* set visible, if anmations.css is not included in theme */
 .appear {
    opacity:1;
 }

#main > div.row-qa-border > div:first-child {
    border: 1rem solid rgb(255, 238, 85)
}

 @media only screen and (min-width:992px) {
    #wrapper {
       padding-top:75px; 
       padding-bottom:45px;
    }

 }