/* achievements */

.info-heading-success, .info-heading-warning, .info-heading-danger {
    font-weight: bold;
}

.info-heading-warning, .info-heading-danger {
    margin: 0 0 1rem 0;
}

.info-heading-success {
    color: var(--valid);
}

.info-heading-warning {
    color: var(--highlight); 
}

.info-heading-danger {
    color: var(--invalid);
}

.progress-bar-danger, .progress-bar-warning, .progress-bar-success {
    height: 1.2rem
}

.progress-bar-danger > div {
    background-color: var(--invalid)
}

.progress-bar-warning > div {
    background-color: var(--highlight)
}

.pb-container-success, .pb-container-warning, .pb-container-danger {
    margin: 1rem auto;
    background-color: var(---bgcolor);
    padding: 1rem;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
    border: 1px solid var(--bglight);
    border-left-width: 3px;
    transition: box-shadow 1s ease-out;
}

.pb-container-success {
    border-left-color: var(--valid);
}

.pb-container-warning {
    border-left-color: var(--highlight);
}

.pb-container-danger {
    border-left-color: var(--invalid);
}