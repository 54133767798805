.form-label .bachicon {
    position:absolute;
    top:0.33rem;
    left:0.5rem;
}

.rightcenter {
    position:absolute;
    right:0;
    bottom:calc(50% - 0.75rem);
    width:1.5rem !important;
    height:1.5rem !important;
}

.bachicon-nopointer {
    pointer-events: none;
}

.bachicon.bachicon-inline {
    display:inline-block;
    width:1.5rem;
    height:1.5rem;
    vertical-align:middle;
    margin-bottom:0.25rem;
}

.bachicon.bachicon-didaktik {
	display:inline-block;
	height:1.5rem;
	vertical-align:middle;
	margin-left:0.1rem;
}