#mainhead>nav {
    background: var(--bars);
    color: var(--mainlight);
    height: 100%;
}

#mainhead {
    height: 75px;
   /* overflow: hidden; /* if hidden, debug token timer not visibile */
}

#mainhead a {
    color: var(--extra);
    text-decoration: none;
}

#profile {
    background: var(--bgwulogo);
    border-radius: 50%;
    border: 3px solid var(--avatarborder);
    width: 50px;
    height: 50px;
    display: block;
    overflow: hidden;
}

#profile img {
    width: 100%;
    min-height: 100%;

}

#menu_opener {
    display: block;
    width: 50px;
    height: auto;
    padding: 1px;
}

#menu_opener hr {
    border: none;
    height: 3px;
    background: var(--navlinklight);
    margin: 6px 5px;
    opacity: 1;
}

#menu_opener hr+hr {
    margin-left: 12px;
}

#menu_opener hr+hr+hr {
    margin-left: 19px;
}

a#change_language {
    font-weight: 700;
    color: var(--navlinklight);
}

.logoutlink {
    white-space: nowrap;
    vertical-align: middle;
}

.truncate {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}

.tokenexpires {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: -15px;
    font-size: 0.75rem;
    z-index: 2;
    font-weight: normal;
    color: var(--text);
    pointer-events: none;
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width:992px) {
    #mainhead {
        position:fixed;
        top:0;
        /*left:0;
        right:0;*/
        width:100%;
        max-width:1600px;
        z-index:1030;  
        height:75px;      
    }
}

.debugmodeinfo {
    position:fixed;
    background:red;
    color:white;
    top:0;
    left:0;
    padding:2px 10px;
    z-index:99999;
}

.mode_ios 
.debugmodeinfo {
    top:1.75rem;
}