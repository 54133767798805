.current-semester {
    font-size: 1rem;
    font-weight: 400;
}

/*.current-semester + .badge {
    width: 5rem; 
}*/

/* not working in firefox */
.option-semester:checked {
    background-color: #d6d6d6 !important;
    /* for IE */
    color: #000000 !important;
}
