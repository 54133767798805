.current-study {
    font-size: 1rem;
    font-weight: 400;
}

.badge {
    font-size: 0.9rem;
    font-weight: 400;
}

.option-study:checked {
    background-color: #d6d6d6 !important;
    /* for IE */
    color: #000000 !important;
}