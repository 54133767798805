.table-container {
    width: 100%;
}

/* Table Styles 
.table-container .rdt_Table {
} 
*/

.table-container .rdt_TableRow {
    background:var(--bgcolor) !important;
    color:var(--text) !important;
    border-color:var(--bglight) !important;
}

.table-container .rdt_Pagination {
    /* filter:invert(); */
    background:var(--bglight) !important;
    color:var(--text) !important;
}

.table-container .rdt_Pagination svg  {
    fill:var(--text);
}

.table-container .rdt_TableHeadRow {
    border-bottom-color:var(--mainmedium) !important;
}

.table-container .rdt_TableCol {
    background-color: var(--mainmedium);
    color: var(--white);
    border-left: solid var(--bgcolor) 0.5px; 
    font-size: 1.2rem;
    font-weight: 300;
    box-shadow: 0px 0.5px 4px var(--lightgrey);
}

.table-container .rdt_TableCell {
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: rgba(0, 0, 0, .12);
    font-size: 1.1rem;
    font-weight: 300;
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    border-color:var(--bglight) !important;
}

.table-container .rdt_TableCol_Sortable > div {
    word-break: normal;
    white-space: break-spaces;
    padding: 0;
}

.table-container .rdt_TableHeadRow>div:first-child {
    border-left: none;
}

.table-container .rdt_TableHeadRow>div:last-child {
    border-right: none;
}

.table-container .rdt_TableRow>div:last-child {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: rgba(0, 0, 0, .12);
}

.table-container input#filter {
    border-radius: 0;
    height: 3.5rem;
    border: none;
    border-bottom: 1px solid var(--grey);
    transition-duration: 0.5s;
    background:var(--bgcolor);
    color:var(--text);
}

.table-container input#filter:active,
.table-container input#filter:focus {
    outline: none;
    border-bottom: 1px solid var(--mainmedium);
    transition-duration: 0.5s;
}

.table-container #filter-container {
    position:relative;
}

.table-container #filter-placeholder{
	color:var(--grey);
	margin-top: -30px;
	margin-left:13px;
	z-index: -1;
}

.table-container button#clear-filter {
    min-height: 0 !important;
    height: 2rem;
    width: 2rem;
    padding: 0 !important;
    border-radius: 50%;
    position: absolute;
    right:0;
    top:1rem;
    background-color: var(--mainmedium) !important;
}

.table-container div[data-column-id="grade"] {
    padding: normal 5px;
}

/*
div[data-column-id="lvtyp_1"] {
    display:none;
}
*/

/*
button#clear-filter:hover {
    background-color: var(--mainmedium) !important
}
*/

@media only screen and (max-width: 600px) {
    .table-container button#clear-filter {
        right: 0;
    }
    .table-container .rdt_Table {
        font-size: 1rem;
    }
    .table-container .rdt_TableHeadRow>div {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        text-align: center;
        justify-content: left;
        font-weight: 500;
        font-size: 0.95rem;
    }
    .table-container div[data-column-id="verdid"].rdt_TableCol,
    .table-container div[data-column-id="verdid"].rdt_TableCell {
        flex-grow: 0;
        min-width: 70px;
    }
    .table-container div[data-column-id="lvtyp_1"].rdt_TableCol,
    .table-container div[data-column-id="lvtyp_1"].rdt_TableCell {
        flex-grow: 0;
        min-width: 60px;
    }
    .table-container .rdt_TableCell {
        padding-left: 0.7rem !important;
        padding-right: 0.2rem !important;
        font-size: 0.9rem;
        font-weight: 500;
    }
    .table-container main a {
        font-weight: 600;
    }
    .table-container .rdt_TableCol_Sortable>div {
        padding-top: 0.4rem;
        padding-left: 0.3rem;
        align-self: start;
        text-align: left;
        line-height: 170%;
    }
}