.card_lvplaner {
    background: var(--bgcolor);
}

.card_one_header {
    border: none;
    border-radius: 0 !important;
    background-color: var(--mainmedium);
    color: var(--bgcolor);
    font-size: 1.4rem;
    font-weight: 600;
}

.card_two_header {
    padding: 5px;
    border: none;
    border-radius: 0 !important;
    background-color: var(--mainmedium);
    color: var(--bgcolor);
    font-weight: 600;
}

#lvplaner_trash_can_icon.btn_close_bach {
    transform: scale(2.5);
    background-repeat: no-repeat;
    padding-left: 1px;
}