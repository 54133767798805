.offcanvas-end {
    max-width:80%;
}

.offcanvas.offcanvas-ios {
    top:1.75rem;
}

#mainmenu {
    background-color:var(--bgmainmenu);
    color:var(--navlink);
}

#mainmenu a {
    color:var(--navlink);
    /*font-weight:600;*/
    text-decoration: none;
    padding-left:1.75rem;
    /*background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z'/%3e%3cpath fill-rule='evenodd' d='M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z'/%3e%3c/svg%3e");*/
    background-repeat:no-repeat;
    background-position:0 0.2rem;
    background-size:auto 1.25rem;
    display:block;
    margin:0.1rem 0;
    line-height:2.1;
    margin-left:-0.25rem;
    position:relative;

}

#mainmenu li:hover {
    background-color:var(--hovermenu);
}

#mainmenu hr {
    margin-top:-0.5rem;
}

#mainmenu .lnk_login a {
    font-weight:600;
    margin-left:1.25rem;
}

#mainmenu .lnk_icon a {
    margin-left:0.75rem;
}

#mainmenu a::after {
    content:'';
    display:inline-block;
    width:2rem;
    height:2rem;
    background-size:1rem 1rem;
    background-position:center center;
    background-repeat:no-repeat;
    position:absolute;
    right:1.25rem;
    top:0;
}