.infobox {
    border:0;
}

.infobox h2 {
    font-weight:800;
}

.infobox  .featurebuttons a {
    border:0;
}

.infobox h2 small {
    display:block;
    font-size:0.66em;
}

.info {
    padding-left:1.75em;
    /*line-height:1.2;*/
    position:relative;
    font-size:0.9rem;
}

.info span {
    background-color:var(--extra);
    color:var(--white);
    border-radius:50%;
    font-style: italic;
    width:1.3em;
    height:1.3em;
    padding-right:0.1em;
    margin:0.1em 0.1em 0.1em -2em;
    display:inline-block;
    text-align:center;
    font-size:0.9em;
    /*font-weight:800;*/
    position:absolute;
    top:-0.1rem;
    left:1.75rem;
    /*line-height:1.3;*/
}

i[title] {
    font-style:normal;
    border-bottom:1px dashed var(--grey);
}

.redirectbox h2 {
    color:var(--mainmedium);
    text-align:center;
    padding-top:2rem !important;
    padding-bottom:2rem !important;
    text-align:center;
}