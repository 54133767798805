/*@import url('./styles/animations.css');*/

html {
    font-size: 16px;
}

body {
    font-family: var(--standardfont);
    background:#ccc;
}


.info span,
h4,
h6,
h2,
h1 {
    font-family: var(--secondfont);
}

.alert-info {
    --bs-alert-color: var(--text);
    --bs-alert-bg: var(--mainlight);
  }

input:autofill,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:autofill,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit !important;
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
    box-shadow: 0 0 0px 1000px #FFFFFF inset;
    background-image:none;

}

#bachroot {
    background:var(--bgcolor);
    max-width:1600px;
    margin:0 auto;
}

#bachroot>* {   
    color: var(--text);
    min-height: 100vh;
    min-width: 320px;
}

#main > * > * {
    background: var(--bgcolor);
}

::placeholder {
    color: var(--placeholder) !important;
    opacity: 1;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--lightgrey) !important;
}

/*
main a:not(.weekcell a, .course),
main a:visited:not(.weekcell a, .course),*/
main a, main a:visited, main a:hover {
    color: var(--hyperlink);
    font-weight: 700;
    word-break: break-word;
    transition:box-shadow 1s ease-out;
}

/* bootstrap classes */

a:active, button:active {
    border-color:transparent !important;
}

h1,
h2,
h3,
h5,
h6,
b {
    font-weight: 600;
}

p {
    font-weight:300;
}

h4 {
    font-weight: 800;
    color: var(--mainmedium);
}

.table {
    color:var(--text);
}

.table > :not(caption) > * > * {
    padding:0;
}

.navbar {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.navbar a.nav-link {
    color: var(--navlink);
}

.bg-light {
    background-color: var(--bglight) !important;
}

.mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
}

.mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.55rem !important;
}

.mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
}

.mx-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.btn {
    border-radius: 0;
    font-weight: 800;
    font-size: 1.2em;
    text-transform:uppercase;
}

.btn-primary,
body a.btn-primary {
    border: 0 none;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--btn-primary) !important;
    color: var(--bgcolor) !important;
    min-height: 3.5rem;
    display:flex;
    justify-content: center;
    align-items: center;
}

.btn-inline {
    display:inline-flex !important;
}

.btn-secondary {
    border: 0 none;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--mainlight) !important;
    color: var(--mainmedium) !important;
    min-height: 3.5rem;
    display:flex;
    justify-content: center;
    align-items: center;
}

.btn-small {
    font-size: 1.1em;
    line-height: 1.2;
}

.form-label {
    position: absolute;
}

.form-label .labeltext {
    display: none;
}


.form-select,
.form-select:focus,
.form-control,
.form-control:focus {
    border-radius: 0;
    border-color: var(--grey) !important;
    padding: 1rem 0.75rem 1rem 4rem;
    background-color:var(--bglight);
    color:var(--text);
    outline: 0;
}

select.form-control:disabled,
input.form-control:read-only {
   background-color: var(--mainlight);
   opacity:0.25;
}

.dataform {
    margin-top: 0;
}

.dataform-margin-top {
    margin-top: 3.75rem;
}

.form-control.is-invalid,
.dataform .form-control.is-invalid {
    border-color: var(--invalid) !important;
}

.form-select {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233AAFA9;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,7.3l2.8-2.8l9.2,9.3l9.2-9.3L24,7.3L12,19.5L0,7.3z'/%3E%3C/svg%3E%0A");
    padding-left:0.5em;
}

.form-select.smallselect {
    background-color:var(--bgcolor);
    padding:0.25rem 0.75rem 0.25rem 0.5rem;
}

.form-control:focus {
    box-shadow: none;
}

.form-select:focus {
    box-shadow: none;
    padding-left:0.5em;
}

.form-control:disabled {
    background:var(--bgcode);
}

.form-check-input {
    border-radius: 0 !important;
    border-color: var(--mainmedium) !important;
    background-color: #fff !important;
    height: 1.2rem;
    width: 1.3rem;
    transition: 400ms;
}

.form-check-input:checked {
    transition: width 150ms ease;
    border-color: var(--mainmedium) !important;
    background-color: var(--mainmedium) !important;
    transition: 250ms;
}

.form-check-input:active,
.form-check-input:hover,
.form-check-input:focus {
    box-shadow: none;
}

.dataform .form-label {
    position: static;
}

.dataform .form-label .labeltext {
    display: block;
    /*font-weight: bold;*/
}

.dataform .form-control {
    border-radius: 0;
}

.dataform .toast {
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
}

.sub-back-btn {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%233aafa9' class='bi bi-chevron-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
    height: 2.5rem;
    width: 3rem;
    background-position: center center;
    background-size: auto 85%;
    margin-top: 1.1rem;
    margin-left: 3rem;
    cursor: pointer;
}

hr {
    opacity: 1;
}

.progress-bar {
    background-color: var(--btn-primary);
}


/* modal */

.modal-title {
    color: var(--white);
}

.modal-dialog {
    position: fixed;
    bottom: 30px;
    left: 0%;
    right: 0%;
    
}

.modal-content {
    border-radius: 0;
    background:var(--bgcolor);
    border:1px solid var(--avatarborder);
}

.modal-header {
    background-color: var(--highlight);
    color: var(--bgcolor);
    border-radius: 0;
    border-bottom:none;
}

.btn-close {
    opacity: 1 !important;
    box-shadow: none !important;
}

.modal-body {
    color: var(--grey);
    font-size: 0.85rem;
}

.modal-body strong {
    color: var(--text);
}

.modal-body a {
    color: var(--grey) !important;
}

/* fix: show scrollbar if modal is open */
.modal-open {
    padding-right:0 !important;
    overflow:auto !important;
}

/* accordion */

.accordion-item,
.accordion-button {
    border-radius: 0 !important;
    border-color: none !important;
    background-color: transparent !important;
    color: var(--bgcolor) !important;
    box-shadow: none !important;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-header {
    background-color: var(--mainmedium);
    color: var(--bgcolor);
    font-family: var(--standardfont);
}

.accordion-header button {
    font-weight: 600;
}

.accordion-body {
    background-color: var(--mainlight);
    color: var(--text);
}

.mainlight {
    color: var(--mainlight);
}

.bg-mainlight {
    background-color: var(--mainlight);
}

.mainmedium {
    color: var(--mainmedium);
}

.bg-mainmedium {
    background-color: var(--bgmedium);
}

.badge.bg-mainmedium {
    background-color: var(--badgemedium);
}

.maindark {
    color: var(--maindark);
}

.bg-maindark {
    background-color: var(--maindark);
}

.badge.bg-maindark {
    background-color: var(--badgedark);
}

.highlight {
    color: var(--highlight);
}

.bg-highlight {
    background-color: var(--highlight);
}

.badge.bg-highlight {
    background-color: var(--badgehighlight);
}

.badge {
    border-radius: 0;
}

#privacy-dropdown + .dropdown-menu[data-bs-popper] {
    background: var(--maindark);
    top: -86px;
    left: 0;
    margin-top: 0.125rem;
    border-radius: 0;
    border:none
}

.day-summary{
    min-height: 7.5rem;
}

.schedule-boxes{
    height: fit-content;
    transition: height 2s;
}

.loader{
    max-height:6rem;
}

@media only screen and (max-width:359px) {
    .navbar {
        font-size:0.9em;        
    }
    .btn {
        font-size:1em;        
    }
}

#debugconsole {
    position: absolute;
    z-index: 100000;
    background: red;
    height: 200px;
    width: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    display:none;
    padding:5px;
}

.mode_ios #debugconsole {
    top:1.75rem;
}

#debugconsole div {
    padding:2px;
}

.versiontext {
    color:var(--lightgrey);
    font-size:0.8em;
}

body .fade:not(.show) {
    opacity:0;
}

.pagetitle {
    /*text-align:center;*/
    color: var(--mainmedium); 
    position:relative;
    padding:0 50px;
}


/* instructor courses */

.teacher-name {
    font-weight: 600;
    font-size: 1.2rem;
    text-decoration: underline var(--text);
}

.teacher-title {
    font-weight: 300;
    font-size: 0.8rem;
}

.teacher-email,
.teacher-email:hover,
.teacher-email:focus {
    font-size: 0.9rem;
}

.teacher-avatar {
    height: 9.2rem;
    width: 9.2rem;
    object-fit: cover;
    object-position: top;
    box-shadow: #9c9c9c99 0px 2px 8px;
    border: solid 0.6rem var(--bgfeaturebutton);
    margin-bottom: 1rem;
}

/* Grades */

.note {
    color: var(--mainmedium);
    font-weight: 600;
    font-size: 1.3rem;
    height: 3rem;
    width: 3rem;
    padding: 0.4rem;
    border: 0.1rem solid var(--mainmedium);
    margin: auto;
    margin-bottom: 0.7rem;
}

.note-title {
    word-break: keep-all;
    max-width: 7rem;
    font-size: 0.77rem;
    text-transform: uppercase;
    font-weight: 600;
}


/* Course Search */

.card {
    border-radius: 0;
    border: 1px solid rgb(139, 152, 165);
}

.card_header {
    border-radius: 0;
    background-color: var(--mainmedium);
    color: var(--bgcolor);
    padding: 1.5rem 2rem;
    border: none;
    font-size: 1.4rem;
    font-weight: 600;
}

.card_body {
    padding: 2.1rem;
    margin-bottom: 0;
    background: var(--bgcolor);
}

.magnifier {
    position: absolute;
    left: 4rem;
    top: 65%;
    height: 1.8rem;
    width: 1.8rem;
}


@media only screen and (max-width: 600px) {
    .card_header {
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 500;
    }
    .card_body {
        padding: 1.3rem;
        margin-bottom: 0;
    }
    .magnifier {
        position: absolute;
        left: 2.5rem;
        top: 65%;
        height: 1.5rem;
        width: 1.5rem;
    }

}

/* back to top */

.back-to-top {
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: var(--grey);
    opacity: .5;
    display: none;
    padding-left: 5px;
    z-index: 1010;
    transition-duration: 0.4s;
}

.back-to-top:hover {
    opacity: 1;
    transition-duration: 0.5s;
}

.back-to-top svg {
    font-size: 4rem;
    padding: 1.3rem 0.9rem 1.3rem 0;
    color: var(--white);
}


/* General Footer Styling */
#mainfoot {
    height: 45px;
}

#mainfoot.iosfooter {
    height:55px;
}

#mainfoot > div {
    background: var(--bars);
    color: var(--navlink);
    height: 100%;
}

/* Navbar Styling */
footer .navbar-nav {
    padding-left: 0.5rem;
}

@media all and (max-width:400px) {
    footer .navbar-nav {
        padding-left: 0rem;
    }
}

/* Support and Theme Styling */
.support,
.choosetheme {
    display: inline-block;
    text-align: center;
    line-height: 0;
    padding: 0.5rem 0.25rem;
}

.support b,
.choosetheme b {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: var(--extra);
    line-height: 20px;
    margin-bottom: 4px;
    color: var(--white);
}

.support span,
.choosetheme span {
    font-size: 0.6rem;
}

/* Theme Icon Styling */
.choosetheme b {
    box-shadow: none !important;
    background-repeat: no-repeat;
    background-position: 0.3rem 0.3rem;
    background-size: auto 1rem;
    background-color: var(--white);
}

@media only screen and (min-width: 992px) {
    #mainfoot {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 1600px;
        z-index: 1030;
        /* left: 0;
        right: 0; */
    }
}

.themebuttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light);
    padding: 10px;
}
.themebuttons button {
    flex: 1;
    box-shadow: none !important;
    padding-left: 2rem;
    background-repeat: no-repeat;
    background-position: 0.5rem center;
    background-size: auto 1rem;
    margin: 0;
    display: flex;
    align-items: center;
}

/* WU LOGO */
#logo_wu {
    text-decoration: none;
}

.logo_wu {
    transform: skew(15deg);
    display: inline-block;
}

.logo_wu_wrapper {
    background-color: var(--bgwulogo);
    margin: 0 0.25rem 0 -2rem;
    padding: 2rem 1rem 2rem 2rem !important;
    transform: skew(-15deg);
    height: 75px;
}

.logo_wu_cropped {
    margin-left: -13px;
    margin-right: -13px;
    padding-left: 13px !important;
    padding-right: 13px !important;
    overflow: hidden;
}

.logo_wu img {
    height: 30px;
    width: auto;
}

.logo_wu span {
    color: var(--text);
    font-size: 0.6rem;
    display: block;
    text-align: center;
}

/* BACH aka myWU logo */
.logo_bach {
    text-decoration: none;
}

.logo_bach span {
    width: 87px;
    height: 35px;
    display: inline-block;
    background-size: cover;
    margin-left: -5px;
    margin-bottom: -5px;
}

#logo_bach+div,
#logo_bach + div {
    font-size: 0.75rem;
    display: block;
    text-align: left;
    color: var(--navlink);
}

#logo_bach img {
    height: 35px;
    width: auto;
    margin-left: -5px;
    margin-bottom: -2px;
}


/* redirect */

.redirectprogress {
    font-size:0.9em;
    text-align:center;
}

/* refresh on mobile scroll */
body {
    overscroll-behavior-y: auto;
}
.pull-to-refresh {
    position: fixed;
    top: -50px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: top 0.7s ease-in-out;
    z-index:100000;
}
.pull-to-refresh.visible {
    top: 0;
}

.form-check-label {
    position:relative;
    top:1px;
    left:3px;
}