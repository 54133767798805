/* thesis details */

.thesis-details ul {
   padding: 0;
   margin: 0;
}

.thesis-details li {
    list-style: none;
}

.thesis-details li a {
    cursor:pointer
}

.thesis-details li a:hover {
    color:var(--extra)
}

.thesis-details li div{
    font-size: 0.95rem;
}

.thesis-details .info {
    font-weight: 300;
}

.thesis-sim-scoring {
    background: var(--extra);
    color: var(--bgcolor);
    padding: 0.5rem;
    text-align: center;
    font-weight: 600;
    width: 50px;
    margin: 0.5rem 0 0 0;
}

.thesis-details .mnr-badge {
    width: 70px;
}

.thesis-details .coauthor-container:not(:first-child) {
    margin-top: 0.3rem;
}

/* card */

.thesis-details .card {
    margin: 0.5rem 0;
    border: none;
    background-color: var(--bgcolor);
}

.thesis-details .card-header {
    color: var(--mainmedium);
    background: var(--bgcode);
    border: none;
    border-radius: 5px;
}
.thesis-details .card-body {
    padding: 1rem;
    background-color: var(--bgcolor);
}

.thesis-details .card-body p {
    font-weight: 600;
}

.thesis-details .card-footer {
    border: none;
    background-color: var(--bgcolor);
    align-items: flex-start;
    display: flex;
    flex-direction: row;
}

.thesis-details .card .card-body {
    padding: 0.8rem 0.3rem 0.5rem;
}

.thesis-details .card .card-body .row p, button {
    margin: 0;
}

.thesis-details .card .card-body .row {
    margin: 0.3rem 0
}

.thesis-details .card .card-body .row > div, div p{
    font-size: 0.95rem;
}

/* buttons */

.thesis-details .thesis-button {
    width: 100%;
    max-width: 200px;
    font-size: 1rem;
    min-height:2.5rem;
    padding: 0.5rem;
    margin-top: 0.5rem;    
}

.thesis-details .thesis-button.btn-secondary {
    cursor: not-allowed;  
}

.thesis-details .thesis-button.btn-secondary#save-thesis {
    cursor: pointer;
}

.thesis-details .thesis-button.btn-danger{
    border:0 none;
    background-color:var(--bs-danger) !important;
}

.thesis-details .delete-button.btn-danger{
    border:0 none;
    background-color:var(--bs-danger) !important;
    max-height: 3rem;
}

/* history table */

.thesis-details .theses-table div[role="rowgroup"] div[role="row"] {
    min-height: 2.5rem;
    border-bottom: 1px solid var(--bglight);
}

.thesis-details .theses-table nav {
    display: none;
}

/* form */

.thesis-details .form-label {
    position: static;
    font-weight: 600;
}

.thesis-details .form-check-label {
    padding-left: 0.5rem;
}

.thesis-details .form-check-input:not(:checked) {
    background-color: var(--bgcolor) !important;
}

.thesis-details .add-button {
    height: 47px !important;
    min-height: 47px !important;
    width: 47px !important;
    font-size: 1.5rem;
}

label[for="confirm_check"], label[for="confirm_submit"] {
    display: block;
    padding-left: 0.5rem;
}

.thesis-details .datebox {
    border-left-color: var(--mainmedium);
    padding: 1rem 0.5rem;
    margin-top: 1rem !important;
}

.thesis-details select.smallselect {
    line-height: 2rem;
}

/* toasts */

.thesis-details .toast-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 5rem;
    bottom: 0;
    overflow: auto;
    width: 100%;
    margin-left: 0;
}

/* oenb info box */

.oenb-info-box .info {
    font-size: 0.85rem;
}

/* similarity report modal */

.no-double-scrollbar {
    overflow-y: hidden !important;
}

#thesis-viewer {
    max-width: 90%;
    position: relative;
    margin: 5rem auto;
    top: 0%;
}   

@media screen and (min-width: 768px) {

    /* toasts */

    .thesis-details .toast-container {
        width: 50%;
        margin-left: 50%;    
    }

}

@media screen and (min-width: 1400px) {

    /* toasts */

    .thesis-details .toast-container {
        width: 30%;
        margin-left: 70%;
    }

}

